.footer_container{
   position: relative;
}
.footer_container > hr{
   border: 1px solid var(--lightgray);
}
.footer{
   padding: 1rem 2rem;
   display: flex;
   flex-direction: column;
   gap: 4rem;
   align-items: center;
   justify-content: center;
   height: 20rem;
}
.social_links{
   display: flex;
   gap: 4rem;
}
.social_links > img{
   width: 2rem;
   height: 2rem;
   cursor: pointer;
}
.logo_f > img{
   width: 10rem;
}
.blur_f1{
   bottom: 0;
   right: 15rem;
   width: 26rem;
   height: 12rem;
   filter: blur(200px);
   background-color: red;
}
.blur_f2{
   bottom: 0;
   left: 15rem;
   width: 26rem;
   height: 12rem;
   filter: blur(200px);
  background: rgb(255, 115, 0); 
}