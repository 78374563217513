.testimonals{
   display: flex;
   gap: 1rem;
   padding: 0 2rem;
}
.left_t{
   display: flex;
   flex: 1 1;
   gap: 2rem;
   flex-direction: column;
   text-transform: uppercase;
   color: white;
}
.left_t>:nth-child(1){
   color: var(--orange);
   font-weight: bold;

}
.left_t>:nth-child(2),
.left_t>:nth-child(3){
   font-size: 3rem;
   font-weight: bold;
   
}

.left_t>:nth-child(4){
   text-transform: none;
   text-align: justify;
   letter-spacing: 2px;
   line-height: 40px;
}
.right_t{
   flex: 1;
   position: relative;
}
.right_t > img{
   position: absolute;
   width: 17rem;
   height: 20rem;
   object-fit: cover;
   right: 8rem;
   top: 2rem;
}
.right_t > :nth-child(1){
   position: absolute;
   width: 17rem;
   height: 20rem;
   border: 2px solid orange;
   right: 9rem;
   top: 0.9rem;
}
.right_t > :nth-child(2){
   position: absolute;
   width: 17rem;
   height: 20rem;
   top: 3rem;
   right: 6.5rem;
   background: var(--planCard);
}
.arrows{
   position: absolute;
   display: flex;
   gap: 1rem;
   bottom: 1rem;
   left: 3rem;
}
.arrows > img{
   width: 1.5rem;
   cursor: pointer;
}



@media screen and (max-width: 768px) {
   .testimonals{
      flex-direction: column;
   }
   .left_t > :nth-child(2),
   .left_t > :nth-child(3){
      font-size: xx-large;
   }
   .left_t > :nth-child(4){
      letter-spacing: 1px;
      line-height: 30px;
      margin-top: -10px;
   }
   .right_t{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
   }
   .right_t > div{
      position: relative;
      display: none;
   }
   .right_t > img{
      position: relative;
      top: 0;
      right: 0;
      align-self: center;
   }
   .right_t > :last-child{
      bottom: 0;
      left: 0;
      display: block;
   }
}