.join{
   display: flex;
   padding: 0 2rem;
   gap: 10rem;
}
.left_j{
   color: white;
   font-size: 3rem;
   font-weight: bold;
   text-transform: uppercase;
   position: relative;
}
.left_j > hr{
   position: absolute;
   width: 10.5rem;
   border: 2px solid var(--orange);
   border-radius: 20%;
   margin: -10px 0;
}
.left_j > div{
   display: flex;
   gap: 1rem;
}
.right_j{
   display: flex;
   justify-content: center;
   align-items: flex-end;
}
.email_container{
   background-color: gray;
   gap: 3rem;
   padding: 1rem 2rem;
   display: flex;
}
.email_container > input{
   background-color: transparent;
   border: none;
   outline: none;
   color: var(--lightgray);
}
::placeholder{
   color: var(--lightgray);
}
.btn_j{
   background-color: var(--orange);
   color: white;
}


@media screen and (max-width: 768px) {
   .join{
      flex-direction: column;
      gap: 1rem;
   }
   .left_j{
      font-size: x-large;
      flex-direction: column;
   }
   .right_j{
      padding: 2rem;
   }
}